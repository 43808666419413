<div class="d-flex flex-column h-100">
  <div class="container-fluid d-flex m-0 p-0 py-2" style="box-shadow: 2px 2px 10px 0px #c3d4ff;
  background-color: #f6f9ff;;z-index: 3;">
      <div class="col-md-2 col-3 logo d-flex justify-content-start">
          <img class="mx-1" src="assets/images/logo.png">
      </div>
      <div class="title col-md-5 col-7  d-flex m-0 p-0 "> 
          <div class="align-self-center">
              <div id="title" class="mb-1">HMS Reporting</div> 
              <!-- <div id="subtitle" class="m-0">{{ subName }}</div>  -->
          </div>
      </div>
      <div class="col-md-5 col-2 navbar p-0 m-0 justify-content-end">
              <ul class="navlist d-flex mx-2">
                  <li *ngIf="practiceName" class="practice align-self-center ps-2 pe-3 mx-4">
                      <div id="practicename" class="text-right">{{practiceName}}</div>
                      <div id="changepractice" class="m-0" (click)="openDialog()"><u>Change Practice</u></div> 
                  </li>
                  <li class="profilepic align-self-center ps-2">
                      <img class="px-1" src="assets/images/profilePic.jpeg">
                  </li>
                  <li class="profilename align-self-center ps-2" [matMenuTriggerFor]="logoutMenu"
                  role="button">
                      <div id="username">{{userName}}</div>
                      <div id="usertype" class="m-0 text-right">Admin</div>
                  </li>
                  <li class="profiledownarrow align-self-center ps-2">
                      <img class="mb-3 me-2" src="assets/images/downarrow.png">
                  </li> 
                  
                  <li class="hamburgermenu align-self-center">
                      <div class="hamburger-menu-div d-flex justify-content-center align-items-center" (click)="sidemenu=true;">
                          <img id="hamburger-menu-close" class="hamburger-menu-close" src="assets/images/HamburgerMenu.png" >
                      </div>
                  </li> 
              </ul> 
              <mat-menu #logoutMenu="matMenu" xPosition="before">
                <button mat-menu-item (click)="logOut()">Logout</button>
            </mat-menu>
      </div>
  </div> 
  <div *ngIf="sidemenu" class="sidemenu container-fluid col-9 col-md-5 h-100 m-0 p-0 position-absolute top-0 end-0" style="background-color: #23418a;z-index: 3;right: 0;">
      <ul class="sidemenu-header m-0 d-flex justify-content-between"> 
          <li class="menu-profilepic align-self-center d-flex justify-content-start align-items-center">
              <div class="image-div d-flex justify-content-center align-items-center">
                  <img src="assets/images/profilePic.jpeg">
              </div>
              <div>
                  <div class="sidemenu-h1 m-0">{{userName}}</div>
                  <div class="sidemenu-h2 mx-0 mt-1 mb-0" style="text-align: start;">( Admin )</div>
              </div>
          </li>
          <li>
              <div class="hamburger-menu-div d-flex justify-content-center align-items-center">
                  <img id="hamburger-menu-open" class="hamburger-menu-open hamburger-menu-white" src="assets/images/CloseMenu.png" (click)="sidemenu=false">   
              </div>
          </li>
      </ul>
      <ul class="sidemenu-practice m-0 d-flex">  
          <li class="menu-profilepic align-self-center d-flex justify-content-start align-items-center px-3 py-1">
              <div *ngIf="practiceName">
                  <div class="sidemenu-h1 m-0">{{practiceName}}</div>
                  <div class="sidemenu-h2 mx-0 mt-1 mb-0" style="text-align: start;color: #0000FF;">Change Practice</div>
              </div>
          </li>
      </ul>       
      <ul class="sub-sidemenu">
          <li class="nav-item" *ngFor="let item of menu">
            <a class="text-white d-flex flex-column justify-content-center nav-link text-center" role="tab" type="button" data-bs-toggle="pill" routerLinkActive="active" [routerLink]="item.path" (click)="sidemenu=false">
                <img [src]="item.icon" alt="menu-icon" class="menu-icon mb-1">
                {{item.name}}
            </a>
        </li>
      </ul>
  </div>

  <mat-drawer-container class="flex-fill">
      <mat-drawer class="leftmenu" mode="side" opened>
          <ul class="flex-column flex-nowrap h-100 menu nav nav-pills">
              <li class="nav-item" *ngFor="let item of menu">
                  <a class="d-flex flex-column justify-content-center nav-link text-center" role="tab" type="button" data-bs-toggle="pill" routerLinkActive="active" [routerLink]="item.path" >
                      <img [src]="item.icon" alt="menu-icon" class="menu-icon mb-1">
                      {{item.name}}
                  </a>
              </li>
          </ul>
      </mat-drawer>
      <mat-drawer-content class="bg-white">
      <router-outlet></router-outlet>
      </mat-drawer-content>
  </mat-drawer-container>
</div>