import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, PageEvent } from '@angular/material';
import { BaseService } from 'src/app/base/base.service';
import { Billing } from 'src/app/_interface/billing.model';
import { ConsentReportService } from '../consent-report/consent-report.service';

@Component({
  selector: 'app-billingcycle',
  templateUrl: './billingcycle.component.html',
  styleUrls: ['./billingcycle.component.css']
})
export class BillingcycleComponent implements OnInit {
  // "PatientId": "ERivera2",
  // "UploadDate": "2021-09-05T00:00:00.000Z",
  // "ActualStartDate": "2021-10-05T00:00:00.000Z",
  // "ActualEndDate": "2021-11-03T00:00:00.000Z",
  // "DistinctDaysOfServices": 25,
  // "Is16Reached": 1,
  // "Threashold16ReachedDate": null,
  // "IsClaimed": null,
  // "ClaimedDate": null,
  public displayedColumns = ['PatientId','UploadDate','ActualStartDate','ActualEndDate', 'DistinctDaysOfServices','Is16Reached','Threashold16ReachedDate','IsClaimed','ClaimedDate']

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  dataSource: MatTableDataSource<Billing> = new MatTableDataSource()
  filter = ''
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  constructor(private reportService: ConsentReportService, private baseService: BaseService) { }

  ngOnInit() {
    this.getBillingReport()
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  getBillingReport(){
    this.reportService.getBillingReport(this.currentPage + 1, this.pageSize, this.filter)
      .subscribe(res => {
        console.log(res)
        if (res['status']) {
          this.dataSource.data = res['data'].data
          setTimeout(() => {
            this.paginator.pageIndex = this.currentPage;
            this.paginator.length = res['data'].pagination[0].TotalRecords;
          });
          console.log(this.dataSource)
        } else {
          this.baseService.showToast(res.errors[0], 'Close');
        }
      }, (error: any) => {
        console.log(error);
        if (error.error.errors) {
          this.baseService.showToast(error.error.errors[0], 'Close');
        } else {
          this.baseService.showToast(error.statusText, 'Close');
        }
      })
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  pageChanged(event: PageEvent) {
    console.log({ event });
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getBillingReport();
  }

  searchReport(){
    this.getBillingReport();
    this.pageSize = 10
    this.currentPage = 0
  }

}
