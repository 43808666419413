<h6 style="color: #23418a;">{{data?.consentState == 'agreement' ? 'Consent Agreement Date' : 'Consent Training Date'}}</h6>
<div mat-dialog-content>
  <mat-form-field>
    <input matInput [max]="maxDate" [matDatepicker]="picker" placeholder="Choose a date" (dateChange)="getSelectedDate($event)">
    <mat-datepicker-toggle matSuffix [for]="picker" style="font-size: 22px;"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <!-- <mat-form-field>
      <input
      type="text"
      [matDatepicker]="from"
      [max]="maxDate"
      matInput
      (dateChange)="updateAgreementAndTrainingDate('training',$event,dataSource[i])"
      />
      <mat-datepicker #from touchUi></mat-datepicker>
  </mat-form-field> -->
</div>
<div mat-dialog-actions class="d-flex justify-content-around">
  <button mat-raised-button class="cancel-btn" (click)="onNoClick()">Cancel</button>
  <button mat-raised-button class="primary-btn" (click)="onSubmit()" cdkFocusInitial>Submit</button>
</div>
