<div class="h-100 d-flex flex-column">
  <div class="d-flex justify-content-between px-2 pt-4">
    <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" >
    </mat-paginator>
    <div>
      <mat-form-field appearance="legacy" class="filter-field">
        <input
          matInput
          [(ngModel)]="filter"
          placeholder="Filter by Patient Id"
        />      
        <button
          mat-button
          *ngIf="filter"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="filter = ''; searchReport()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <button class="btn btn-primary mx-2" (click)="searchReport()">Search</button>
    </div>
    
  </div>
  <div class="flex-fill overflow-auto px-2 pb-4">
    <table mat-table [dataSource]="dataSource" class="w-100" >
      <ng-container matColumnDef="PatientId">
        <th
          class="font-weight-bold"
          mat-header-cell
          *matHeaderCellDef
        >
          Patient Id
        </th>
        <td mat-cell *matCellDef="let element">{{ element.PatientId }}</td>
      </ng-container>
      <ng-container matColumnDef="UploadDate">
        <th mat-header-cell *matHeaderCellDef >First Upload Date</th>
        <td mat-cell *matCellDef="let element">{{ element.UploadDate | date: 'MM/dd/yyyy' }}</td>
      </ng-container>
      <ng-container matColumnDef="ActualStartDate">
        <th mat-header-cell *matHeaderCellDef >Billing Cycle Start Date</th>
        <td mat-cell *matCellDef="let element">{{ element.ActualStartDate | date: 'MM/dd/yyyy' }}</td>
      </ng-container>
      <ng-container matColumnDef="ActualEndDate">
        <th mat-header-cell *matHeaderCellDef>Billing Cycle End Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.ActualEndDate | date: 'MM/dd/yyyy' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="DistinctDaysOfServices">
        <th mat-header-cell *matHeaderCellDef>Distinct Days Of Services</th>
        <td mat-cell *matCellDef="let element">
          {{ element.DistinctDaysOfServices }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Is16Reached">
        <th mat-header-cell *matHeaderCellDef>16 count Reached</th>
        <td mat-cell *matCellDef="let element">
          {{ element.Is16Reached == null ? '-' : (element.Is16Reached == 1 ? 'YES' : 'NO') }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Threashold16ReachedDate">
        <th mat-header-cell *matHeaderCellDef>Threshold 16 Reached Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.Threashold16ReachedDate == null ? '-' : element.Threashold16ReachedDate | date: 'MM/dd/yyyy' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="IsClaimed">
        <th mat-header-cell *matHeaderCellDef>Is Claimed</th>
        <td mat-cell *matCellDef="let element">
          {{ element.IsClaimed == null ? '-' : (element.IsClaimed == 1 ? 'YES' : 'NO') }}
        </td>
      </ng-container>

      <ng-container matColumnDef="ClaimedDate">
        <th mat-header-cell *matHeaderCellDef>Claimed Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.ClaimedDate == null ? '-' : element.ClaimedDate | date: 'MM/dd/yyyy' }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>

