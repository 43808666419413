<div class="d-flex flex-column h-100">
  <div>
    <div class="pt-3 position-relative">
      <div *ngIf="lastUploadedDate"  style="font-size: 14px" class="text-right mx-2">
        <b class="px-2">Last Uploaded:</b>{{ lastUploadedDate | date: "MMM d, y, h:mm:ss a" }}
      </div>
      <form (ngSubmit)="getMasterList()" [formGroup]="filterForm" class="d-flex align-items-center justify-content-center">
        <div class="row mx-0 align-items-center">
          <div class="px-3">
            <mat-form-field appearance="standard">
              <mat-label>Month & Year</mat-label>
              <input matInput [matDatepicker]="from" formControlName="from" [max]="maxDate" readonly />
              <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
              <mat-datepicker #from startView="year" (monthSelected)="chosenMonthHandler($event, from)"
                panelClass="filter"></mat-datepicker>
              <mat-error>Month & Year is required</mat-error>
            </mat-form-field>
          </div>
          <!-- <div class="px-3">
            <mat-form-field appearance="standard">
              <mat-label>To</mat-label>
              <input
                matInput
                [matDatepicker]="to"
                formControlName="to"
                [max]="maxDate"
                readonly
              />
              <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
              <mat-datepicker #to></mat-datepicker>
              <mat-error>To date is required</mat-error>
            </mat-form-field>
          </div> -->
          <div class="px-3">
            <button mat-raised-button type="submit" [disabled]="filterForm.invalid" class="primary-btn">
              Submit
            </button>
          </div>
        </div>
        
        <div class="align-items-center d-flex justify-content-end position-absolute" style="right: 12px;">
          <button mat-raised-button [disabled]="selection.selected.length < 1" class="primary-btn"
            (click)="newClaim=true;openDialog()">
            Initiate Claim
          </button>
        </div>
      </form>
      
    </div>
  </div>
  <div class="flex-fill overflow-auto px-2 pb-4">
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <!-- <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()"
            [disabled]="!fetchEligiblePatients().length"
          >
          </mat-checkbox> -->
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox
            *ngIf="element.date_99453 || element.date_99454 || element.date_99457 || element.date_99458_1 || element.date_99458_2"
            (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(element) : null"
            [checked]="selection.isSelected(element)" [aria-label]="checkboxLabel(element)"
            [disabled]="showClaimStatus(element)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="PatientId">
        <th class="font-weight-bold" mat-header-cell *matHeaderCellDef mat-sort-header>
          Patient Id
        </th>
        <td mat-cell *matCellDef="let element">{{ element.PatientId }}</td>
      </ng-container>

      <ng-container matColumnDef="FirstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
        <td mat-cell *matCellDef="let element">{{ element.FirstName }}</td>
      </ng-container>

      <ng-container matColumnDef="LastName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
        <td mat-cell *matCellDef="let element">{{ element.LastName }}</td>
      </ng-container>

      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Patient Name</th>
        <td mat-cell *matCellDef="let element">{{ element.Name }}</td>
      </ng-container>

      <ng-container matColumnDef="dateOfBirth">
        <th mat-header-cell *matHeaderCellDef>D.O.B</th>
        <td mat-cell *matCellDef="let element">
          {{ element.DateOfBirth | date: "MM/dd/yyyy" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="diagnosis">
        <th mat-header-cell *matHeaderCellDef>Diagnosis</th>
        <td mat-cell *matCellDef="let element">{{ element.Diagnosis }}</td>
      </ng-container>

      <ng-container matColumnDef="initialUploadDate">
        <th mat-header-cell *matHeaderCellDef>Initial Upload Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.date_99453 | date: "MM/dd/yyyy" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="trainingCompletedate">
        <th mat-header-cell *matHeaderCellDef>Training Complete date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.date_99453 | date: "MM/dd/yyyy" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="96453">
        <th mat-header-cell *matHeaderCellDef>99453</th>
        <td mat-cell *matCellDef="let element">
          {{ element.date_99453 | date: "MM/dd/yyyy" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="count_99454">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Upload Count (Days)
        </th>
        <td mat-cell *matCellDef="let element" class="text-center">
          <!-- <div class="d-flex align-items-center"> -->
          {{
          element.UploadCount == null || element.UploadCount == 0
          ? "-"
          : element.UploadCount
          }}
          <!-- <a class="table-link pl-1"
              ><mat-icon [inline]="true">assignment</mat-icon></a
            >
          </div> -->
        </td>
      </ng-container>

      <ng-container matColumnDef="99454">
        <th mat-header-cell *matHeaderCellDef>99454</th>
        <td mat-cell *matCellDef="let element">
          {{
          element.date_99454 == null
          ? "-"
          : (element.date_99454 | date: "MM/dd/yyyy")
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="total_Service_time_99457">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Total Service Time99457 (Minutes)
        </th>
        <td mat-cell *matCellDef="let element" class="text-center">
          {{ element.TotalServiceTime }}
        </td>
      </ng-container>

      <ng-container matColumnDef="99457">
        <th mat-header-cell *matHeaderCellDef>99457</th>
        <td mat-cell *matCellDef="let element">
          {{
          element.date_99457 == null
          ? "-"
          : (element.date_99457 | date: "MM/dd/yyyy")
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="99458">
        <th mat-header-cell *matHeaderCellDef>99458 #1</th>
        <td mat-cell *matCellDef="let element">
          {{
          element.date_99458_1 == null
          ? "-"
          : (element.date_99458_1 | date: "MM/dd/yyyy")
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="99458x2">
        <th mat-header-cell *matHeaderCellDef>99458 #2</th>
        <td mat-cell *matCellDef="let element">
          {{
          element.date_99458_2 == null
          ? "-"
          : (element.date_99458_2 | date: "MM/dd/yyyy")
          }}
        </td>
      </ng-container>

      <!-- <ng-container matColumnDef="TrainingDate">
        <th mat-header-cell *matHeaderCellDef>Training Status</th>
        <td mat-cell *matCellDef="let element">
          {{ element.TrainingDate == null ? "-" : "Complete" }}
        </td>
      </ng-container> -->

      <ng-container matColumnDef="InitialUploadDate">
        <th mat-header-cell *matHeaderCellDef>Initial Upload Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.FirstUploadDate == null ? "-" : (element.FirstUploadDate | date: "dd-MMM") }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Kickoffdate">
        <th mat-header-cell *matHeaderCellDef>Training Complete Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.KickOffDate == null ? "-" : (element.KickOffDate | date: "dd-MMM") }}
        </td>
      </ng-container>

      <ng-container matColumnDef="BillingCycleStartDate">
        <th mat-header-cell *matHeaderCellDef>Billing Cycle</th>
        <td mat-cell *matCellDef="let element">
          {{
          element.BillingCycleStartDate == null
          ? "-"
          : (element.BillingCycleStartDate | date: "dd-MMM") +
          " to " +
          (element.BillingCycleEndDate | date: "dd-MMM")
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>
          {{
          router.url === "/reports/masterreport-claim"
          ? "Claim Status"
          : "Claim Initiated"
          }}
        </th>
        <td mat-cell *matCellDef="let element">
          <!-- <mat-icon>more_horiz</mat-icon> -->
          <ng-container *ngIf="router.url === '/reports/masterreport-claim'">
            <ng-container *ngIf="element.date_99453">
              <!-- [disabled]="element.IsClaimed_99453" -->
              <mat-checkbox [disableRipple]="true" [checked]="element.IsClaimed_99453" (change)="
              newClaim=true;!element.IsClaimed_99453 ? openDialog('99453', element, cb1) : viewClaim('99453', element, cb1)
                " class="pb-2" #cb1><a [class.need-attention]="!element.IsClaimed_99453" tabindex="0">99453 ({{
                  element.date_99453 | date: "MM/dd/yy" }})</a></mat-checkbox><br />
            </ng-container>
            <ng-container *ngIf="element.date_99454">
              <!-- [disabled]="element.IsClaimed_99454" -->
              <mat-checkbox [disableRipple]="true" [checked]="element.IsClaimed_99454" (change)="
              newClaim=true;!element.IsClaimed_99454 ? openDialog('99454', element, cb2) : viewClaim('99454', element, cb2)
                " class="pb-2" #cb2><a [class.need-attention]="!element.IsClaimed_99454" tabindex="0">99454 ({{
                  element.date_99454 | date: "MM/dd/yy" }})</a></mat-checkbox><br />
            </ng-container>
            <ng-container *ngIf="element.date_99457">
              <!-- [disabled]="element.IsClaimed_99457" -->
              <mat-checkbox [disableRipple]="true" [checked]="element.IsClaimed_99457" (change)="
              newClaim=true;!element.IsClaimed_99457 ? openDialog('99457', element, cb3) : viewClaim('99457', element, cb3)
                " class="pb-2" #cb3><a [class.need-attention]="!element.IsClaimed_99457" tabindex="0">99457 ({{
                  element.date_99457 | date: "MM/dd/yy" }})</a></mat-checkbox><br />
            </ng-container>
            <ng-container *ngIf="element.date_99458_1">
              <!-- [disabled]="element.IsClaimed_99458_1" -->
              <mat-checkbox [disableRipple]="true" [checked]="element.IsClaimed_99458_1" (change)="
              newClaim=true;!element.IsClaimed_99458_1
                    ? openDialog('99458#1', element, cb4)
                    : viewClaim('99458#1', element, cb4)
                " class="pb-2" #cb4><a [class.need-attention]="!element.IsClaimed_99458_1" tabindex="0">99458 #1 ({{
                  element.date_99458_1 | date: "MM/dd/yy" }})</a></mat-checkbox><br />
            </ng-container>
            <ng-container *ngIf="element.date_99458_2">
              <!-- [disabled]="element.IsClaimed_99458_2" -->
              <mat-checkbox [disableRipple]="true" [checked]="element.IsClaimed_99458_2" (change)="
              newClaim=true;!element.IsClaimed_99458_2
                    ? openDialog('99458#2', element, cb5)
                    : viewClaim('99458#2', element, cb5)
                " class="pb-2" #cb5><a [class.need-attention]="!element.IsClaimed_99458_2" tabindex="0">99458 #2 ({{
                  element.date_99458_2 | date: "MM/dd/yy" }})</a></mat-checkbox>
            </ng-container>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="claimDetails">
        <th mat-header-cell *matHeaderCellDef>
        Claim Details <br> (ClaimID | ClaimedDate)
        </th>
        <td mat-cell *matCellDef="let element">
          <!-- <mat-icon>more_horiz</mat-icon> -->
          <ng-container *ngIf="router.url === '/reports/masterreport-claim'">
          
              <ng-container *ngIf="element.date_99453">
                {{
                element.IsClaimed_99453 == null
                ? "Not Claimed"
                : ( element.ClaimId_99453 + " | "+(element.ClaimedDate_99453 | date: "MM/dd/yyyy"))
                }}
                <br>
              </ng-container>
              <ng-container *ngIf="element.date_99454">
                {{
                element.IsClaimed_99454 == null
                ? "Not Claimed"
                : ( element.ClaimId_99454 + " | "+(element.ClaimedDate_99454 | date: "MM/dd/yyyy"))
                }}
                <br>
              </ng-container>
              <ng-container *ngIf="element.date_99457">
                {{
                element.IsClaimed_99457 == null
                ? "Not Claimed"
                : ( element.ClaimId_99457 + " | "+(element.ClaimedDate_99457 | date: "MM/dd/yyyy"))
                }}
                <br>
              </ng-container>
              <ng-container *ngIf="element.date_99458_1">
                {{
                element.IsClaimed_99458_1 == null
                ? "Not Claimed"
                : ( element.ClaimId_99458_1 + " | "+(element.ClaimedDate_99458_1 | date: "MM/dd/yyyy"))
                }}
                <br>
              </ng-container>

              <ng-container *ngIf="element.date_99458_2">
                {{
                element.IsClaimed_99458_2 == null
                ? "Not Claimed"
                : ( element.ClaimId_99458_2 + " | "+(element.ClaimedDate_99458_2 | date: "MM/dd/yyyy"))
                }}
                <br>
              </ng-container>

          </ng-container>
        </td>
      </ng-container>


      <!-- <ng-container matColumnDef="details">
      <th mat-header-cell *matHeaderCellDef> Details </th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button color="primary" (click)="redirectToDetails(element.id)">
            <mat-icon class="mat-18">reorder</mat-icon>
        </button>
      </td>
    </ng-container> -->

      <ng-container matColumnDef="update">
        <th mat-header-cell *matHeaderCellDef>Update</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button color="accent">
            <mat-icon class="mat-18">system_update</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef>Delete</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button color="warn">
            <mat-icon class="mat-18">delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
<ng-template #optional>
  <form (ngSubmit)="onSubmit()" [formGroup]="claimForm" #form="ngForm">
    <div mat-dialog-content>
      <mat-form-field class="w-100">
        <mat-label>Claim ID (Optional)</mat-label>
        <input matInput formControlName="ClaimId" />
      </mat-form-field>
      <br />
      <mat-form-field class="w-100">
        <mat-label>Claim Date (Optional)</mat-label>
        <input matInput [max]="maxDate" [matDatepicker]="picker" formControlName="ClaimedDate" readonly />
        <mat-datepicker-toggle matSuffix [for]="picker" style="font-size: 22px"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div mat-dialog-actions class="d-flex justify-content-around">
      <button mat-raised-button class="cancel-btn" type="button" mat-dialog-close>
        Cancel
      </button>
      <button mat-raised-button class="primary-btn" type="submit" mat-dialog-close cdkFocusInitial>
        Submit
      </button>
    </div>
  </form>
</ng-template>