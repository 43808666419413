<div class="h-100 d-flex flex-column">
  <div class="d-flex justify-content-between align-items-center px-2 pt-2">
    <div class="pt-3 position-relative">
      <form [formGroup]="filterForm" (ngSubmit)="getConsentReport()" class="d-flex align-items-center justify-content-center">
        <div class="row mx-0 align-items-center">
          <div class="px-3">
            <mat-form-field appearance="standard">
              <mat-label>Month & Year</mat-label>
              <input matInput [matDatepicker]="from" formControlName="from" [max]="maxDate" readonly />
              <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
              <mat-datepicker #from startView="year" (monthSelected)="chosenMonthHandler($event, from)"
                panelClass="filter"></mat-datepicker>
              <mat-error>Month & Year is required</mat-error>
            </mat-form-field>
          </div>
          <div class="px-3">
            <button mat-raised-button type="submit" [disabled]="filterForm.invalid" class="primary-btn">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
    <div *ngIf="dataSource?.data">
      <mat-form-field appearance="legacy" class="filter-field">
        <input
          matInput
          [(ngModel)]="filter"
          placeholder="Search By Name or Patient Id"
        />
        <button
          mat-button
          *ngIf="filter"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="filter = ''; searchReport()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <button class="btn btn-primary mx-2" (click)="searchReport()">Search</button>
    </div>
  </div>
  <div class="d-flex justify-content-end">
    <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" >
    </mat-paginator>
  </div>
  <div class="flex-fill overflow-auto px-2 pb-4">
    <table mat-table [dataSource]="dataSource" class="w-100">
      <ng-container
        [matColumnDef]="column.key"
        *ngFor="let column of displayedColumns"
      >
        <th
          class="font-weight-bold"
          mat-header-cell
          *matHeaderCellDef="let element"
        >
          {{ column.title }}
        </th>
        <ng-container [ngSwitch]="column.key">
          <!-- <ng-container *ngSwitchCase="'TrainingDate'">
            <td mat-cell *matCellDef="let element; let i = index">
              <ng-container *ngIf="!element[column.key]; else defaultValue">
                <a class="table-link" (click)="openDialog(element, 'training')"
                  >Update Status</a
                >
              </ng-container>
              <ng-template #defaultValue>
                {{ element[column.key] | date }}
              </ng-template>
            </td>
          </ng-container> -->
          <!-- <ng-container *ngSwitchCase="'ConsentAgreementDate'">
            <td mat-cell *matCellDef="let element; let i = index">
              <ng-container *ngIf="!element[column.key]; else defaultValue">
                <a class="table-link" (click)="openDialog(element, 'agreement')"
                  >Update Status</a
                >
              </ng-container>
              <ng-template #defaultValue>
                {{ element[column.key] | date }}
              </ng-template>
            </td>
          </ng-container> -->
          <ng-container *ngSwitchCase="'FirstName'">
            <td mat-cell *matCellDef="let element">
              {{ element["FirstName"] }} {{ element["LastName"] }}
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'MeterAssignedDate'">
            <td mat-cell *matCellDef="let element">
              {{ element[column.key] | date }}
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'date_99453'">
            <td mat-cell *matCellDef="let element">
              {{ element[column.key] | date }}
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'KickOffDate'">
            <td mat-cell *matCellDef="let element">
              <!-- {{ element[column.key] | date }} -->
              {{ element.KickOffDate == null ? "-" : (element.KickOffDate | date: "dd-MMM") }}
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'FirstUploadDate'">
            <td mat-cell *matCellDef="let element">
              <!-- {{ element.FirstUploadDate == null ?  "-" : (elementFirstUploadDate | date) }} -->
              {{ element.FirstUploadDate == null ? "-" : (element.FirstUploadDate | date: "dd-MMM") }}
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'action'">
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.date_99453">
                <!-- [disabled]="element.IsClaimed_99453" -->
                <mat-checkbox [disableRipple]="true" [checked]="element.IsClaimed_99453" (change)="
                newClaim=true;!element.IsClaimed_99453 ? openDialog('99453', element, cb1) : viewClaim('99453', element, cb1)
                  " class="pb-2" #cb1><a [class.need-attention]="!element.IsClaimed_99453" tabindex="0">99453 ({{
                    element.date_99453 | date: "MM/dd/yy" }})</a></mat-checkbox><br />
              </ng-container>
            </td>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <td mat-cell *matCellDef="let element">
              {{ element[column.key] }}
            </td>
          </ng-container>
        </ng-container>
      </ng-container>
      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns | map: 'key'; sticky: true"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns | map: 'key'"
      ></tr>
     
    </table>
    <ng-container *ngIf="isDataLoaded && dataSource.data.length == 0">
      <div class="text-center py-3">No Data Available</div>
    </ng-container>
  </div>
</div>

<ng-template #optional>
  <form (ngSubmit)="onSubmit()" [formGroup]="claimForm" #form="ngForm">
    <div mat-dialog-content>
      <mat-form-field class="w-100">
        <mat-label>Claim ID (Optional)</mat-label>
        <input matInput formControlName="ClaimId" />
      </mat-form-field>
      <br />
      <mat-form-field class="w-100">
        <mat-label>Claim Date (Optional)</mat-label>
        <input matInput [max]="maxDate" [matDatepicker]="picker" formControlName="ClaimedDate" readonly />
        <mat-datepicker-toggle matSuffix [for]="picker" style="font-size: 22px"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div mat-dialog-actions class="d-flex justify-content-around">
      <button mat-raised-button class="cancel-btn" type="button" mat-dialog-close>
        Cancel
      </button>
      <button mat-raised-button class="primary-btn" type="submit" mat-dialog-close cdkFocusInitial>
        Submit
      </button>
    </div>
  </form>
</ng-template>
