<div class="position-relative">
  <!-- <div class="text-end position-absolute" style="right: 10px;">
    <mat-icon (click)="onCancelClick()" class="text-gray">cancel</mat-icon>
  </div>   -->
  <h2 mat-dialog-title class="text-center" style="color: #25284E;">Select Practice</h2>
  <!-- <div class="position-absolute" style="top: 7px;right: 0;font-size: 16px;color: #0000EE;cursor: pointer;" [routerLink]="['/home/practice']" [mat-dialog-close]="true" cdkFocusInitial>Add Practice</div> -->
</div>
<div mat-dialog-content class="px-5" (scroll)="onScroll($event)">
  <!-- <mat-form-field class="w-100 position-sticky bg-white" style="top: 0;z-index: 1;">
    <input matInput placeholder="Search Practice" [(ngModel)]="practiceName" #nameAutoCompleteTextbox/>
  </mat-form-field> -->
  <div *ngFor="let practice of practiceList" mat-dialog-close class="d-flex align-items-center" style="border-bottom: 1px solid lightgrey;cursor: pointer;" (click)="onOkClick(practice)">
    <img width="50" height="50" style="border-radius: 100%;" [src]="practice.LogoUrl" alt="{{practice.Name}}">
    <h4 class="m-0 p-3" style="font-size: 18px;">{{practice.Name}}</h4>
  </div> 
</div>
