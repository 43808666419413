<form (ngSubmit)="onSubmit()" [formGroup]="claimForm" #form="ngForm">
  <div mat-dialog-content>
    <mat-form-field class="w-100">
      <mat-label>Claim ID (Optional)</mat-label>
      <input matInput formControlName="ClaimId" />
    </mat-form-field>
    <br />
    <mat-form-field class="w-100">
      <mat-label>Claim Date (Optional)</mat-label>
      <input matInput [max]="maxDate" [matDatepicker]="picker" formControlName="ClaimedDate" readonly />
      <mat-datepicker-toggle matSuffix [for]="picker" style="font-size: 22px"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="d-flex justify-content-around">
    <button mat-raised-button class="cancel-btn" type="button" mat-dialog-close>
      {{newClaim?'Cancel':'Close'}}
    </button>
    <button mat-raised-button class="primary-btn" type="button" mat-dialog-close *ngIf="!newClaim" (click)="clearClaim()">
      Clear
    </button>
    <button mat-raised-button class="primary-btn" type="submit" mat-dialog-close cdkFocusInitial>
      {{newClaim?'Submit':'Update'}}
    </button>
  </div>
</form>