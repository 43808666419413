<!-- <div class="align-items-center container-fluid d-flex h-100 bg-blue"> -->
<div class="align-items-center container-fluid d-flex h-100">
  <div class="row main-content text-center">
    <div class="col-md-4 text-center company__info">
      <span style="background: white;border-radius: 8px;" class="company__logo py-3"
        ><h2>
          <img src="assets/images/logo.png" alt="logo" class="login-logo" /></h2
      ></span>
      <h4 class="company_title mt-2 text-white">HMS Reports</h4>
    </div>
    <div class="col-md-8 col-xs-12 col-sm-12 login_form">
      <div class="container-fluid">
        <div class="row">
          <h2 class="login-heading">Log In</h2>
        </div>
        <div class="row">
          <form
            class="form-group w-100"
            [formGroup]="loginForm"
            #form="ngForm"
            (ngSubmit)="form.valid ? loginUser() : ''"
          >
            <div class="row position-relative">
              <input
                type="text"
                name="username"
                id="username"
                class="form__input"
                placeholder="Username"
                formControlName="UserName"
              />
              <div
                *ngIf="
                  (form.submitted && userName.invalid) ||
                  (userName.invalid && (userName.dirty || userName.touched))
                "
                class="error-warning position-absolute small text-danger"
              >
                Userame is required
              </div>
            </div>
            <div class="row position-relative">
              <!-- <span class="fa fa-lock"></span> -->
              <input
                type="password"
                name="password"
                id="password"
                class="form__input"
                placeholder="Password"
                formControlName="Password"
              />
              <div
                *ngIf="
                  (form.submitted && password.invalid) ||
                  (password.invalid && (password.dirty || password.touched))
                "
                class="error-warning position-absolute small text-danger"
              >
                Password is required
              </div>
            </div>
            <!-- <div class="row">
              <input
                type="checkbox"
                name="remember_me"
                id="remember_me"
                class=""
              />
              <label for="remember_me">Remember Me!</label>
            </div> -->
            <div class="row justify-content-center">
              <button class="btn" type="submit">Submit</button>
            </div>
          </form>
        </div>
        <!-- <div class="row">
          <p>Don't have an account? <a href="#">Register Here</a></p>
        </div> -->
      </div>
    </div>
  </div>
</div>
