import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckbox, MatDatepicker, MatPaginator, MatTableDataSource, PageEvent } from '@angular/material';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BaseService } from 'src/app/base/base.service';
import { Report99453 } from 'src/app/_interface/99453.model';
import { DialogoverviewComponent } from '../dialogoverview/dialogoverview.component';
import { ConsentReportService } from './consent-report.service';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ClaimOverviewComponent } from '../claim-overview/claim-overview.component';
import { MasterReport } from 'src/app/_interface/masterreport.model';
import { MasterListService } from '../masterlist/masterlist.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MMMM, YYYY',
  },
  display: {
    dateInput: 'MMMM, YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-consent-report',
  templateUrl: './consent-report.component.html',
  styleUrls: ['./consent-report.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class ConsentReportComponent implements OnInit {
  filterForm: FormGroup

  displayedColumns = [
    { title: 'Patient ID', key: 'PatientId' },
    { title: 'Name', key: 'FirstName' },
    { title: 'Initial Upload Date', key: 'FirstUploadDate'  },
    { title: 'Training Completion Date', key: 'KickOffDate'  },
    { title: 'Claim Status', key: 'action'  }
  ]
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  dataSource: MatTableDataSource<Report99453> = new MatTableDataSource()
  maxDate = new Date()
  loading: boolean = false
  filter = '';
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  public get period(): AbstractControl { return this.filterForm.get('from') }
  newClaim: boolean = true
  claimForm: FormGroup = new FormGroup({
    PatientId: new FormControl(''),
    Insurance: new FormControl(''),
    InsuranceDate: new FormControl(''),
    ClaimId: new FormControl(null),
    ClaimedDate: new FormControl(null),
    IsClaimed: new FormControl(null)
  })
  public get claimDate(): AbstractControl { return this.claimForm.get('ClaimedDate') }
  selection = new SelectionModel<MasterReport>(true, []);
  reports = ['99453']
  @ViewChild('optional', { static: false }) template: any;
  formSubmitSubscription: Subscription
  isDataLoaded: boolean = false;

  constructor(private masterListService: MasterListService,private consentService: ConsentReportService, router: Router, public dialog: MatDialog, private baseService: BaseService,private fb: FormBuilder) { }

  ngOnInit() {
    this.filterForm = this.fb.group({
      'from': [moment(), Validators.required],
      // 'to': [new Date(), Validators.required]
    })
    this.getConsentReport()
    this.formSubmitSubscription = this.masterListService.formSubmitted$.subscribe(res => {
      console.log(res)
      this.onSubmit()
    })
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(){
    this.formSubmitSubscription.unsubscribe()
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.period.value;
    ctrlValue.year(normalizedMonth.year())
    ctrlValue.month(normalizedMonth.month());
    this.period.setValue(ctrlValue);
    datepicker.close();
  }

  getConsentReport() {
    this.consentService.getConsentReport('eligible',this.currentPage + 1, this.pageSize, this.filter,moment(this.period.value).startOf('month').format('YYYY-MM-DD'))
      .subscribe(res => {
        console.log(res)
        if (res['status']) {
          this.dataSource.data = res['data'].data
          this.isDataLoaded = true
          setTimeout(() => {
            this.paginator.pageIndex = this.currentPage;
            this.paginator.length = res['data'].pagination[0].TotalRecords;
          });
          console.log(this.dataSource)
        } else {
          this.baseService.showToast(res.errors[0], 'Close');
        }
      }, (error: any) => {
        console.log(error);
        if (error.error.errors) {
          this.baseService.showToast(error.error.errors[0], 'Close');
        } else {
          this.baseService.showToast(error.statusText, 'Close');
        }
      })
  }

  updateAgreementAndTrainingDate(consentState: string, event: any, data: any) {
    console.log(consentState, event, data)
    var date = new Date(event).toString().split(/\+|-/)[0]
    var date_string_as_Y_M_D = (new Date(date)).toISOString().split('T')[0];
    var dataSend = {
      "Date": date_string_as_Y_M_D,
      "PatientId": data.PatientId
    }
    console.log(dataSend)
    this.consentService.updateAgreementOrTraining(consentState == 'agreement' ? 'patient/insurer/99453/concent' : 'patient/insurer/99453/training', dataSend).subscribe(res => {
      console.log(res)
      // Call consent api again to update
      if (res.status) {
        this.getConsentReport()
      } else {
        this.baseService.showToast(res.errors[0], 'Close');
      }
    }, (error: any) => {
      console.log(error);
      if (error.error.errors) {
        this.baseService.showToast(error.error.errors[0], 'Close');
      } else {
        this.baseService.showToast(error.statusText, 'Close');
      }
    })
  }

  // openDialog(data: any, state: string): void {
  //   console.log(data, state)
  //   const dialogRef = this.dialog.open(DialogoverviewComponent, {
  //     width: '250px',
  //     disableClose: true,
  //     data: { consentState: state, data: data }
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log(result)
  //     if (result != undefined) {
  //       console.log('The dialog was closed');
  //       this.updateAgreementAndTrainingDate(state, result.data, data)
  //     }
  //   });
  // }

  openDialog(insurance?: string, patientDetail?: MasterReport, checkBox?: MatCheckbox) {
    this.claimForm.reset({
      "PatientId": "",
      "Insurance": "",
      "InsuranceDate": "",
      "ClaimId": null,
      "ClaimedDate": null,
      "IsClaimed": null
    })
    if (checkBox) {
      checkBox.checked = null
      this.claimForm.patchValue({
        "PatientId": patientDetail['PatientId'],
        "Insurance": insurance,
        "InsuranceDate": moment(patientDetail[`date_${insurance}`.replace('#', '_')]).format('YYYY-MM-DD')
      })
    }
    this.dialog.open(ClaimOverviewComponent, { disableClose: true, data: { form: this.claimForm, newClaim: this.newClaim } })
  }

  onSubmit() {
    console.log('onSubmit')
    if (this.claimDate.value) this.claimDate.setValue(moment(this.claimDate.value).format('YYYY-MM-DD'))
    this.masterListService.putClaimUpdate(this.initiateClaim()).subscribe(res => {
      console.log(res)
      if (res['status']) {
        this.updateReports()
      } else {
        this.baseService.showToast(res.errors[0], 'Close');
      }
    }, (error: any) => {
      console.log(error);
      if (error.error.errors) {
        this.baseService.showToast(error.error.errors[0], 'Close');
      } else {
        this.baseService.showToast(error.statusText, 'Close');
      }
    })
  }

  initiateClaim() {
    const insurancesToClaim = { "InsuranceInfo": [] }
    if (this.selection.selected.length) {
      console.log(this.selection.selected)
      this.selection.selected.forEach(patient => {
        this.reports.forEach(report => {
          if (patient[`date_${report}`.replace('#', '_')] && !patient[`IsClaimed_${report}`.replace('#', '_')])
            insurancesToClaim.InsuranceInfo.push({
              "PatientId": patient['PatientId'],
              "Insurance": report,
              "InsuranceDate": moment(patient[`date_${report}`.replace('#', '_')]).format("YYYY-MM-DD"),
              "ClaimId": this.claimForm.get('ClaimId').value,
              "ClaimedDate": this.claimForm.get('ClaimedDate').value ? moment(this.claimForm.get('ClaimedDate').value).format('YYYY-MM-DD') : this.claimForm.get('ClaimedDate').value,
              "IsClaimed": this.claimForm.get('IsClaimed').value
            })
        })
      })
    }
    else {
      insurancesToClaim.InsuranceInfo.push(this.claimForm.value)
    }
    console.log(insurancesToClaim)
    return insurancesToClaim
  }

  updateReports() {
    if (this.selection.selected.length) {
      console.log(this.selection.selected)
      this.selection.selected.forEach(patient => {
        this.reports.forEach(report => {
          if (patient[`date_${report}`.replace('#', '_')] && !patient[`IsClaimed_${report}`.replace('#', '_')])
            patient[`IsClaimed_${report}`.replace('#', '_')] = this.claimForm.get('IsClaimed').value
        })
      })
    } else {
      this.dataSource.data[this.dataSource.data.findIndex(patient => patient['PatientId'] === this.claimForm.get('PatientId').value)][`IsClaimed_${this.claimForm.get("Insurance").value}`.replace('#', '_')] = this.claimForm.get('IsClaimed').value
    }
    this.selection.clear()
  }

  viewClaim(insurance: string, patientDetail: MasterReport, checkBox: MatCheckbox) {
    console.log({ ...patientDetail, Insurance: insurance })
    this.masterListService.getClaimData({ ...patientDetail, Insurance: insurance }).subscribe(res => {
      console.log(res)
      if (res['status']) {
        this.newClaim = false
        this.openDialog(insurance, patientDetail, checkBox)
        checkBox.checked = true
        this.claimForm.patchValue({
          "ClaimId": res['data'][0][`ClaimId_${insurance.replace('#', '_')}`],
          "ClaimedDate": res['data'][0][`ClaimedDate_${insurance.replace('#', '_')}`],
          "IsClaimed": res['data'][0][`IsClaimed_${insurance.replace('#', '_')}`]
        })
      } else {
        this.baseService.showToast(res.errors[0], 'Close');
      }
    }, (error: any) => {
      console.log(error);
      if (error.error.errors) {
        this.baseService.showToast(error.error.errors[0], 'Close');
      } else {
        this.baseService.showToast(error.statusText, 'Close');
      }
    })
    checkBox.checked = true
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  pageChanged(event: PageEvent) {
    console.log({ event });
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getConsentReport();
  }

  searchReport(){
    this.getConsentReport();
    this.pageSize = 10
    this.currentPage = 0
  }

}
