// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // url: "https://dev.e-commerce.mavinapps.com/hms_dev/api/",
  // url: "http://192.168.1.137:8080/api/",
  url: "https://portal-uat.hmsrpm.com/hms_qa/api/",
  // url: "https://hms.mavinapps.com/hms_qa/api/",
  // url: "https://20.122.3.5/hms_dev/",
  // https://dev.e-commerce.mavinapps.com/
  api_key: "d68166f4-da69-45c5-978e-29a6ba7a1159"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
