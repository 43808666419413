<div class="d-flex flex-column h-100">
  <div class="d-flex justify-content-between align-items-center px-2 pt-2">
    <div class="pt-3 position-relative">
      <form [formGroup]="filterForm" (ngSubmit)="getReport96454()" class="d-flex align-items-center justify-content-center">
        <div class="row mx-0 align-items-center">
          <div class="px-3">
            <mat-form-field appearance="standard">
              <mat-label>Month & Year</mat-label>
              <input matInput [matDatepicker]="from" formControlName="from" [max]="maxDate" readonly />
              <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
              <mat-datepicker #from startView="year" (monthSelected)="chosenMonthHandler($event, from)"
                panelClass="filter"></mat-datepicker>
              <mat-error>Month & Year is required</mat-error>
            </mat-form-field>
          </div>
          <div class="px-3">
            <button mat-raised-button type="submit" [disabled]="filterForm.invalid" class="primary-btn">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
    <div>
      <mat-form-field appearance="legacy" class="filter-field">
        <!-- (keyup)="applyFilter($event.target.value)" -->
        <input matInput [(ngModel)]="filter" placeholder="Filter by Patient Id and Name"/>
        <button
          mat-button
          *ngIf="filter"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="filter = ''; searchReport()"
        >
          <mat-icon>close</mat-icon>
        </button>     
      </mat-form-field>
      <button class="btn btn-primary mx-2" (click)="searchReport()">Search</button>
    </div>
  </div>
  <div class="d-flex justify-content-end">
    <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" >
    </mat-paginator>
  </div>
  <div class="flex-fill overflow-auto px-2 pb-4">
    <table mat-table [dataSource]="dataSource" class="w-100" >
      <ng-container matColumnDef="PatientId">
        <th
          class="font-weight-bold"
          mat-header-cell
          *matHeaderCellDef

        >
          Patient Id
        </th>
        <td mat-cell *matCellDef="let element">{{ element.PatientId }}</td>
      </ng-container>
      <ng-container matColumnDef="FirstName">
        <th mat-header-cell *matHeaderCellDef >First Name</th>
        <!-- <td mat-cell *matCellDef="let element">{{ element.FirstName }}</td> -->
        <td mat-cell *matCellDef="let element">
          {{ element["FirstName"] }} {{ element["LastName"] }}
        </td>
      </ng-container>
      <!-- <ng-container matColumnDef="LastName">
        <th mat-header-cell *matHeaderCellDef >Last Name</th>
        <td mat-cell *matCellDef="let element">{{ element.LastName }}</td>
      </ng-container> -->
      <ng-container matColumnDef="DateOfBirth">
        <th mat-header-cell *matHeaderCellDef>D.O.B</th>
        <td mat-cell *matCellDef="let element">
          {{ element.DateOfBirth | date: 'MM/dd/yyyy' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="InsuranceId">
        <th mat-header-cell *matHeaderCellDef>Insurance Id</th>
        <td mat-cell *matCellDef="let element">
          {{ element.InsuranceId }}
        </td>
      </ng-container>
      <ng-container matColumnDef="UploadCount">
        <th mat-header-cell *matHeaderCellDef>Upload Count</th>
        <td mat-cell *matCellDef="let element">
          {{ element.UploadCount }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Date_99454">
        <th mat-header-cell *matHeaderCellDef>Date 99454</th>
        <td mat-cell *matCellDef="let element">
          {{ element.date_99454 | date }}
        </td>
      </ng-container>
      <ng-container matColumnDef="MeasurementDateTime">
        <th mat-header-cell *matHeaderCellDef>Measurement Date&Time</th>
        <td mat-cell *matCellDef="let element">
          {{ element.MeasurementDateTime | date: 'M/d/yy, h:mm a' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="BillingCycleStartDate">
        <th mat-header-cell *matHeaderCellDef>Billing Start Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.BillingCycleStartDate | date}}
        </td>
      </ng-container>
      <ng-container matColumnDef="BillingCycleEndDate">
        <th mat-header-cell *matHeaderCellDef>Billing End Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.BillingCycleEndDate | date }}
        </td>
      </ng-container>
      <ng-container matColumnDef="UploadDateTime">
        <th mat-header-cell *matHeaderCellDef>Upload Date&Time</th>
        <td mat-cell *matCellDef="let element">
          {{ element.UploadDateTime | date: 'M/d/yy, h:mm a' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Claim Status</th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.date_99454">
            <mat-checkbox [disableRipple]="true" [checked]="element.IsClaimed_99454" (change)="
            newClaim=true;!element.IsClaimed_99454 ? openDialog('99454', element, cb2) : viewClaim('99454', element, cb2)
              " class="pb-2" #cb2><a [class.need-attention]="!element.IsClaimed_99454" tabindex="0">99454 ({{
                element.date_99454 | date: "MM/dd/yy" }})</a></mat-checkbox><br />
          </ng-container>
        </td>
        
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <ng-container *ngIf="isDataLoaded && dataSource.data.length == 0">
      <div class="text-center py-3">No Data Available</div>
    </ng-container>
  </div>
</div>
